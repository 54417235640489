import { render, staticRenderFns } from "./default.vue?vue&type=template&id=6eb2b762"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TopNav: require('/home/code/flirt-frontends/packages/twizie/components/TopNav.vue').default,DiscountTimeBonusTopBanner: require('/home/code/flirt-frontends/packages/twizie/components/DiscountTimeBonusTopBanner.vue').default,PageFooter: require('/home/code/flirt-frontends/packages/twizie/components/PageFooter.vue').default,EntryWarning: require('/home/code/flirt-frontends/packages/twizie/components/EntryWarning.vue').default,DiscountTimeBonusPopup: require('/home/code/flirt-frontends/packages/twizie/components/DiscountTimeBonusPopup.vue').default,LikeModal: require('/home/code/flirt-frontends/packages/twizie/components/LikeModal.vue').default})
