/* eslint-disable prettier/prettier */
export default {
  state() {
    return {
      paymentCountry: null,
      signupFlowEmbeddedSiteEntryWarning: null,
      form: {
        email: '',
        name: '',
        date_of_birth: null,
        signup_message: '',
        password: '',
      },
      push: {
        isCapable: null,
        isSubscribed: null,
        lastMessage: null,
      },
      discountcode: null,
      welcomeDiscountcode: null,
      timeBonus: null,
      countdowninterval: null,
      geolocation_permission: null,
      geolocation_geocode: null,
    };
  },
  mutations: {
    set_geolocation_permission(state, p) {
      state.geolocation_permission = p;
    },
    set_geolocation_geocode(state, p) {
      state.geolocation_geocode = p;
    },
    set_discountcode(state, p) {
      state.discountcode = p;
    },
    set_welcome_discountcode(state, p) {
      state.welcomeDiscountcode = p;
    },
    SET_PAYMENT_COUNTRY(state, p) {
      state.paymentCountry = p;
    },
    SET_FORM_FIELD(state, p) {
      state.form[p.field] = p.value;
    },
    SET_PUSH_CAPABLE(state, p) {
      state.push.isCapable = p;
    },
    SET_PUSH_SUBSCRIBED(state, p) {
      state.push.isSubscribed = p;
    },
    SET_LAST_MESSAGE(state, p) {
      state.push.lastMessage = p;
    },
    SET_SIGNUP_FLOW_EMBEDDED_SITE_ENTRY_WARNING(state, p) {
      state.signupFlowEmbeddedSiteEntryWarning = p;
    },
    SET_TIME_BONUS_STATE(state, p) {
      if (p === null) {
        clearInterval(state.timeBonus?.countdownInterval);
      }

      state.timeBonus = p;
    },
  },
  actions: {
    load_discountcode({ commit }, payload) {
      return this.$axios
        .get('/api/discountcode.php', {
          params: payload
        })
        .then((res) => {
          commit('set_discountcode', res.data);
        });
    },
    load_welcome_discountcode({ commit }) {
      return this.$axios
        .get('/api/discountcode.php', {
          params: {
            welcome: true
          }
        })
        .then((res) => {
          commit('set_welcome_discountcode', res.data);
        })
        .catch(() => {});
    },

    load_time_bonus({ commit, state }, payload) {
      if (state.timeBonus?.countdownInterval) {
        return;
      }

      if (!this.$auth.loggedIn || payload === null) {
        clearInterval(state.timeBonus?.countdownInterval);
        commit('SET_TIME_BONUS_STATE', null);
        return;
      }

      if (this.$auth.user?.paid_user) {
        clearInterval(state.timeBonus?.countdownInterval);
        commit('SET_TIME_BONUS_STATE', null);
        return;
      }

      
      const now = this.$dayjs();
      const signupDate = this.$dayjs(this.$auth.user?.signup_date);
      const timeDiffSeconds = now.diff(signupDate, 'second');
      const totalSeconds = 4 * 60 * 60;
      
      if (timeDiffSeconds >= totalSeconds) {
        commit('SET_TIME_BONUS_STATE', null);
        return;
      }

      const discountCodeSettings = {
          "code": "TIMEBONUS25",
          "country": null,
          "user_id": null,
          "expires": null,
          "value_percentage": "25.0",
          "currency": null,
          "minimum_order_value": null,
          "status": "active",
          "validity": "once-per-user",
          "expired": false,
          "paymentmethods": null
      };

      let remainingSeconds = totalSeconds - timeDiffSeconds;

      const interval = setInterval(() => {
        if (!this.$auth.loggedIn) {
          clearInterval(interval);
          commit('SET_TIME_BONUS_STATE', null);
          return;
        }

        if (remainingSeconds <= 0) {
          clearInterval(interval);
          commit('SET_TIME_BONUS_STATE', null);
          return;
        }

        remainingSeconds--;

        const hrs = Math.floor(remainingSeconds / 3600);
        const mins = Math.floor((remainingSeconds % 3600) / 60);
        const secs = remainingSeconds % 60;

        const hours = hrs.toString().padStart(2, '0');
        const minutes = mins.toString().padStart(2, '0');
        const seconds = secs.toString().padStart(2, '0');
        
        commit('SET_TIME_BONUS_STATE', {
          isVisible: true,
          countdownTimer: { hours, minutes, seconds },
          countdownInterval: interval,
          ...discountCodeSettings,
        });
      }, 1000);
    },
    setSignupFlowEmbeddedSiteEntryWarning({ commit }, p) {
      commit('SET_SIGNUP_FLOW_EMBEDDED_SITE_ENTRY_WARNING', p);
    },
    setPushCapable({ commit }, p) {
      commit('SET_PUSH_CAPABLE', p);
    },
    setGeolocationPermission({ commit }, p) {
      commit('set_geolocation_permission', p);
    },
    setGeolocationGeocode({ commit }, p) {
      commit('set_geolocation_geocode', p);
    },
    setPushSubscribed({ commit }, p) {
      commit('SET_PUSH_SUBSCRIBED', p);
    }, setPushLastMessage({ commit }, p) {
      commit('SET_LAST_MESSAGE', p);
    },

    setPaymentCountry({ commit }, p) {
      commit('SET_PAYMENT_COUNTRY', p);
    },
    updateFormField({ commit }, p) {
      commit('SET_FORM_FIELD', p);
    },
    async nuxtServerInit({ commit }, { req, route }) {
      const params = Object.assign({
        _force_domain: req.headers.host
      }, route.query);
      await this.$axios.get('/api/settings.php',
        {
          params
        }).then((r) => {
          commit('settings/set_settings', r.data.settings);
        

          const cookieValue = this.$i18n.getLocaleCookie();
          if (!cookieValue && r.data.settings.domain_language) {
            const code = r.data.settings.domain_language.substr(0, 2);
            const lang = this.$i18n.locales.find((x) => x.code === code);
            if (lang) {
              this.app.FORCELANG = code;
              this.$i18n.setLocaleCookie(code);
              this.$i18n.setLocale(code);
            }
          }

        });
    },
  },
  getters: {
    active_discount_code(state) {
      return state.timeBonus || state.welcomeDiscountcode;
    },
  },
};
